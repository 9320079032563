import React, { useState } from "react";
import {
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,FormControlLabel,Radio,RadioGroup,Divider
} from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import SummonNotice from "./SummonNotice";
import ComplainantApp from "./ComplainantApp";
import PatternGeneratorView from "./PatternGeneratorView";
import MenuIcon from "@mui/icons-material/Menu";
import "./App.css";

function App() {
  const [theme, setTheme] = useState("dark"); // Default theme is Dark Mode
  const [open, setOpen] = useState(false); // Control for the side menu drawer

  const handleThemeChange = (event, newTheme) => {
    if (newTheme) setTheme(newTheme); // Update the theme if a valid selection is made
  };

  // Toggle the drawer state
  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  // Determine styles based on the selected theme
  const getThemeStyles = (theme) => {
    switch (theme) {
      case "dark":
        return {
          backgroundColor: "#333", // Dark mode background color
          color: "#fff", // Text color for dark mode
        };
      case "silver":
        return {
          backgroundColor: "#C0C0C0", // Silver mode background color
          color: "#000", // Text color for silver mode
        };
      case "white":
        return {
          backgroundColor: "#fff", // White mode background color
          color: "#000", // Text color for white mode
        };
      default:
        return {
          backgroundColor: "#333", // Default to dark mode background
          color: "#fff", // Default text color
        };
    }
  };

  // Apply the styles for the drawer based on the theme
  const drawerStyles = getThemeStyles(theme);

  return (
    <Router>
      <div className={`app-container ${theme}`}>
        <Container style={{ padding: "10px" }}>
          {/* Side Menu Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            style={{ position: "fixed", top: 10, left: 10 }}
          >
            <MenuIcon />
          </IconButton>

          {/* Slide View Drawer with dynamic theme */}
          <Drawer
  anchor="left"
  open={open}
  onClose={toggleDrawer(false)}
  PaperProps={{
    style: {
      backgroundColor: drawerStyles.backgroundColor,
      color: drawerStyles.color,
    },
  }}
>
  <List>
    <ListItem button component={Link} to="/summon-notice" onClick={toggleDrawer(false)}>
      <ListItemText
        primary="Summon Notice"
        style={{ color: drawerStyles.color }}
      />
    </ListItem>
    <ListItem button component={Link} to="/complainant" onClick={toggleDrawer(false)}>
      <ListItemText
        primary="Complainant Entry"
        style={{ color: drawerStyles.color }}
      />
    </ListItem>
    <ListItem button component={Link} to="/pattern-generator" onClick={toggleDrawer(false)}>
      <ListItemText
        primary="Pattern Generator"
        style={{ color: drawerStyles.color }}
      />
    </ListItem>
  </List>

  <div style={{ padding: "10px", textAlign: "center" }}>
    <Divider style={{ marginBottom: "10px" }} />
    <h4 style={{ margin: "10px 0" }}>Select Theme</h4>
    <RadioGroup
      value={theme}
      onChange={(event) => setTheme(event.target.value)}
      style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
    >
      <FormControlLabel value="dark" control={<Radio />} label="Dark Mode" />
      <FormControlLabel value="silver" control={<Radio />} label="Silver Mode" />
      <FormControlLabel value="white" control={<Radio />} label="White Mode" />
    </RadioGroup>
  </div>
</Drawer>


          {/* Render Routes */}
          <Routes>
            {/* Default Route */}
           <Route path="/" element={<PatternGeneratorView />} />

            {/* <Route path="/summon-notice" element={<SummonNotice />} /> */}
            {/* <Route path="/complainant" element={<ComplainantApp />} /> */}
            <Route path="/pattern-generator" element={<PatternGeneratorView />} />
          </Routes>

        </Container>
      </div>
    </Router>
  );
}

export default App;
