import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const officers = [
  "BADAR & INSPECTOR",
  "AMJAD & SUB-INSPECTOR",
  "USMAN & SUB-INSPECTOR",
];

const operators = [
  "Telenor",
  "Ufone",
  "Jazz/Warid",
  "Zong"
  // "SCO",
  // "Onic",
  // "Rox",
];

const PatternGeneratorView = () => {
  const [formData, setFormData] = useState({
    enquiryCase: "FIR/", // Enforce "FIR/" prefix
    phoneNumber: "",
    priority: "",
    operator: operators[0], // Default operator
    officer: officers[0], // Default officer
    type: "CDR",
    imeiNumber: "",
    startDate: null,
    endDate: null,
    pattern: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Ensure "FIR/" prefix for Enquiry/Case field
    if (name === "enquiryCase" && !value.startsWith("FIR/")) {
      setError("Enquiry/Case Number must start with 'FIR/'.");
      return;
    }

    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };
      generatePattern(updatedFormData);
      return updatedFormData;
    });
    setError("");
  };

  const handleDateChange = (field, date) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev, [field]: date };
      generatePattern(updatedFormData);
      return updatedFormData;
    });
  };

  const handleDateRangeClick = (days) => {
    const startDate = dayjs().subtract(days, "day"); // Calculate the start date based on the day range
    const endDate = dayjs(); // Always set the end date to today
    setFormData((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const handleFirstMonthsClick = (months) => {
    const startDate = dayjs().subtract(362, "day").startOf("day"); // Start 362 days ago from today
    const endDate = dayjs(startDate).add(months, "month").endOf("day"); // Add months to the start date
    setFormData((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };
    
  const generatePattern = (updatedFormData = formData) => {
    const {
      enquiryCase,
      phoneNumber,
      operator,
      officer,
      priority,
      type,
      imeiNumber,
      startDate,
      endDate,
    } = updatedFormData;
  
    // Validation
    if (!enquiryCase.startsWith("FIR/")) {
      setError("Enquiry/Case Number must contain 'FIR/'.");
      return;
    }
  
    if (type === "CDR" && (!phoneNumber || !/^923\d{9}$/.test(phoneNumber))) {
      setError("Phone number must start with 923 and be exactly 12 digits.");
      return;
    }
  
    if (type === "IMEI" && (!imeiNumber || imeiNumber.length < 14 || imeiNumber.length > 15)) {
      setError("IMEI number must be 14 or 15 digits.");
      return;
    }
  
    if (!startDate || !endDate) {
      setError("Start and End dates are required.");
      return;
    }
  
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
      setError("End date must be after Start date.");
      return;
    }
  
    setError(""); // Clear errors
  
    const formattedStartDate = dayjs(startDate).format("DD-MM-YYYY");
    const formattedEndDate = dayjs(endDate).format("DD-MM-YYYY");
  
    let pattern = "";

    
  
    if (type === "CDR") {
      switch (operator) {
        case "Telenor":
          pattern = `tpn:${phoneNumber}:${formattedStartDate}:${formattedEndDate}:`;
          break;
        case "Jazz/Warid":
        case "Zong":
        case "SCO":
        case "Onic":
        case "Rox":
          pattern = `A;${phoneNumber};${dayjs(startDate).format("DD/MM/YYYY")};${dayjs(endDate).format(
            "DD/MM/YYYY"
          )};`;
          break;
        case "Ufone":
          pattern = `MSISDN|Both|${dayjs(startDate).format("MM/DD/YYYY")}|${dayjs(endDate).format(
            "MM/DD/YYYY"
          )}|${phoneNumber}`;
          break;
        default:
          pattern = "Unsupported operator for CDR.";
      }
    } else if (type === "IMEI") {
      // Generate IMEI patterns for all operators
      const allPatterns = operators.map((op) => {
        switch (op) {
          case "Telenor":
            const imei14 = imeiNumber.slice(0, 14);
            return `tpi:${imeiNumber}:${formattedStartDate}:${formattedEndDate}` + '\n' +`tpi:${imei14}:${formattedStartDate}:${formattedEndDate}`;
          case "Jazz/Warid":
            return `I;${imeiNumber};${dayjs(startDate).format("DD/MM/YYYY")};${dayjs(
              endDate
            ).format("DD/MM/YYYY")};`;
          case "Ufone":
            return `IMEI|Both|${dayjs(startDate).format("MM/DD/YYYY")}|${dayjs(endDate).format(
              "MM/DD/YYYY"
            )}|${imeiNumber}`;
          case "Zong":
            return `A;${imeiNumber};${dayjs(startDate).format("DD/MM/YYYY")};${dayjs(
              endDate
            ).format("DD/MM/YYYY")};`;
          default:
            return `${op}: Unsupported IMEI format.`;
        }
      });
  
      // Combine all operator patterns into a single string
      pattern = allPatterns.join("\n");
    }
  
    setFormData({
      ...updatedFormData,
      pattern: `${priority ? `${priority} ` : ""}Please provide the ${
        type === "CDR" ? "CDR (Call+Sms+Data)" : "IMEI CDRs" } for the following: ${enquiryCase} ${officer}\n\n${pattern}`,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Container>
      <Typography variant="h4" gutterBottom textAlign="center">
        CDR / IMEI Pattern Generator
      </Typography>
      <Grid container spacing={3}>
        {/* Input Form Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <FormControl fullWidth margin="normal">
              <TextField
                label="Enquiry/Case Number"
                name="enquiryCase"
                value={formData.enquiryCase}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value.startsWith("FIR/")) {
                    setError("Enquiry/Case Number must start with 'FIR/'.");
                    return;
                  }
                  setFormData({ ...formData, enquiryCase: value });
                  setError("");
                }}
                helperText="Enquiry/Case Number must start with 'FIR/'."
                error={!!error}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={(e) => {
                  const value = e.target.value;
                  setFormData({ ...formData, type: value });

                  // Reset phoneNumber or imeiNumber when switching types
                  if (value === "CDR") {
                    setFormData((prev) => ({ ...prev, imeiNumber: "" }));
                  } else if (value === "IMEI") {
                    setFormData((prev) => ({ ...prev, phoneNumber: "" }));
                  }
                }}
              >
                <MenuItem value="CDR">CDR</MenuItem>
                <MenuItem value="IMEI">IMEI</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Operator</InputLabel>
              <Select
                name="operator"
                value={formData.operator}
                onChange={handleChange}
              >
                {operators.map((operator) => (
                  <MenuItem key={operator} value={operator}>
                    {operator}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {formData.type === "CDR" && (
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      setError("Phone number must contain only digits.");
                      return;
                    }
                    if (value && !/^923\d{9}$/.test(value)) {
                      setError("Phone number must start with 923 and be exactly 12 digits.");
                    } else {
                      setError("");
                    }
                    setFormData({ ...formData, phoneNumber: value });
                  }}
                  helperText="Must start with 923 and be 12 digits."
                  error={!!error && formData.phoneNumber.length > 0}
                />
              </FormControl>
            )}
            {formData.type === "IMEI" && (
              <FormControl fullWidth margin="normal">
                <TextField
                  label="IMEI Number"
                  name="imeiNumber"
                  value={formData.imeiNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!/^\d*$/.test(value)) {
                      setError("IMEI number must contain only digits.");
                      return;
                    }
                    setFormData({ ...formData, imeiNumber: value });
                    if (value.length > 0 && (value.length < 14 || value.length > 15)) {
                      setError("IMEI number must be 14 or 15 digits.");
                    } else {
                      setError("");
                    }
                  }}
                  helperText="IMEI number must be 14 or 15 digits."
                  error={!!error && formData.imeiNumber.length > 0}
                />
              </FormControl>
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel>Investigation Officer</InputLabel>
              <Select
                name="officer"
                value={formData.officer}
                onChange={handleChange}
              >
                {officers.map((officer) => (
                  <MenuItem key={officer} value={officer}>
                    {officer}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Priority</InputLabel>
              <Select
                name="priority"
                value={formData.priority}
                onChange={handleChange}
              >
                <MenuItem value="">Normal</MenuItem>
                <MenuItem value="Most Urgent">Most Urgent</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
            <DatePicker
              label="Start Date"
              value={formData.startDate}
              onChange={(date) => handleDateChange("startDate", date)}
              minDate={dayjs().subtract(364, "days")}
              maxDate={dayjs()}
              disableFuture
              fullWidth
            />
            </FormControl>
            <FormControl fullWidth margin="normal">
            <DatePicker
              label="End Date"
              value={formData.endDate}
              onChange={(date) => handleDateChange("endDate", date)}
              minDate={formData.startDate}
              maxDate={dayjs()}
              fullWidth
              disabled={!formData.startDate}
            />
            </FormControl>
            <FormControl fullWidth margin="normal">
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
  <Button variant="outlined" onClick={() => handleFirstMonthsClick(3)}>
    First 3 Months
  </Button>
  <Button variant="outlined" onClick={() => handleFirstMonthsClick(6)}>
    First 6 Months
  </Button>
  <Button variant="outlined" onClick={() => handleFirstMonthsClick(9)}>
    First 9 Months
  </Button>
 </Stack>
            </FormControl>
            <FormControl fullWidth margin="normal">
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
  <Button variant="outlined" onClick={() => handleDateRangeClick(90)}>
    Last 3 Months
  </Button>
  <Button variant="outlined" onClick={() => handleDateRangeClick(180)}>
  Last 6 Months 
  </Button>
  <Button variant="outlined" onClick={() => handleDateRangeClick(270)}>
  Last 9 Months 
  </Button>
  <Button variant="outlined" onClick={() => handleDateRangeClick(362)}>
  Last 12 Months
  </Button>
</Stack>
</FormControl>
          </Box>
        </Grid>
        {/* Generated Pattern Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => generatePattern()}
              sx={{ marginBottom: 2, marginTop:2 }}
            >
              Generate Pattern
            </Button>
            <TextField
              label="Generated Pattern"
              value={formData.pattern}
              fullWidth
              multiline
              rows={12}
              InputProps={{ readOnly: true }}
            />
            <Button
  variant="contained"
  color="secondary"
  fullWidth
  sx={{ mt: 2 }}
  onClick={() => navigator.clipboard.writeText(formData.pattern)}
  disabled={!formData.pattern} // Enabled only if the pattern field has text
>
  Copy Text
</Button>

          </Box>
        </Grid>
      </Grid>
      {error && <Typography color="error" textAlign="center" mt={2}>{error}</Typography>}
    </Container>
  </LocalizationProvider>
  );
};

export default PatternGeneratorView;
